// import { ReactComponent as Logo } from "../logo.svg";
import React, {useEffect, useState} from "react";
import {gql, useLazyQuery, useReactiveVar} from "@apollo/client";
import { Trans, t } from "@lingui/macro";

import { SearchItemObj } from "../types/itunes";
import SearchItem from "../components/SearchItem";
import {
  searchDataLoading,
  searchData,
  searchValue,
  loadError,
  host, selectedLanguage, currentUrl
} from "../utils/cache";
import Layout from "../layouts/Layout";
import { hostTitle } from "../utils";
import Head from "../utils/Head";
import usePlayer from "../hooks/usePlayer";

import {PcstLogo, PodcastRuLogo, PodcastKzLogo} from "../components/Logos";
import Rings from "../components/Rings";

const GET_ITUNES = gql`
  query GetItunes($term: String!) {
    itunes(term: $term) @client {
      itunes_id
      title
      artwork
      rss
    }
  }
`;

interface SearchProps {
  error404?: boolean | undefined;
  error500?: boolean | undefined;
}

function Search(props: SearchProps) {
  const [value, setValue] = useState(searchValue());
  const { isReady, clean } = usePlayer();
  const [getItunes] = useLazyQuery(GET_ITUNES, {ssr: false});
  const language = useReactiveVar(selectedLanguage);
  const showRussian = language !== "uk";

  useEffect(() => {
    if (!isReady) {
      clean();
    }
  }, [isReady, clean]);

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    if (!value) return;
    searchValue(value);
    getItunes({ variables: { term: value } });
  };

  const items = !searchDataLoading()
    ? searchData().result.map((item: SearchItemObj, index: number) => {
        return <SearchItem key={index} item={item} />;
      })
    : [];
  let logoUrl = <PcstLogo />;
  switch (host().toLowerCase()) {
    case "podcast.ru":
      logoUrl = <PodcastRuLogo />;
      break;
    case "podcast.kz":
      logoUrl = <PodcastKzLogo />;
      break;
  }

  let preparedTitle = t`Podcast.ru создаёт удобную и красивую страницу для вашего подкаста со ссылками на все популярные сервисы.`;
  preparedTitle = preparedTitle.replace('Podcast.ru', hostTitle());
  currentUrl('');
  return (<Layout>
    <Head url={""} description={preparedTitle} />
    <div className="content">
      <div className="container">
        <div className="big-logo">
          {props.error404 ? (
            <div className="err-img">
              <img src="/img/404.svg" alt="404" />
            </div>
          ) : null}
          {props.error500 ? (
            <div className="err-img">
              <img src="/img/500.svg" alt="500" />
            </div>
          ) : null}
          <a href="/" title={hostTitle()}>
            {logoUrl}
            {/*<Logo />*/}
          </a>
        </div>
        {props.error404 ? (
          <p className="big">
            <span className="red"><Trans>Страница не найдена.</Trans></span>
            <br />
            <Trans>Попробуйте ещё раз или воспользуйтесь поиском по подкастам</Trans>
          </p>
        ) : null}
        {props.error500 ? (
          <p className="big">
            <span className="red"><Trans>Что-то произошло.</Trans></span>
            <br />
            <Trans>Попробуйте ещё раз или воспользуйтесь поиском по подкастам</Trans>
          </p>
        ) : null}
        {!props.error500 && !props.error404 ? (
          <h1 className="big">{preparedTitle}</h1>
        ) : null}
        <div className={`search-block ${loadError() ? "style-error" : ""}`}>
          <form onSubmit={handleSubmit}>
            <div className={`input ${value ? "" : "placeholder"}`}>
              <input
                type="search"
                placeholder={t`Название подкаста...`}
                value={value}
                onChange={e => setValue(e.target.value)}
              />
              <div className="clear" onClick={() => setValue("")}>
                <svg height="20" width="20">
                  <use xlinkHref="/img/sprite.svg#cross" />
                </svg>
              </div>
            </div>
            <div className="submit">
              <button className="btn" type="submit">
                <Trans>Получить ссылку</Trans>
              </button>
            </div>
          </form>
          {loadError() ? (
            <div className="results-text">
              {" "}<Trans>Не удалось загрузить результаты поиска. Обновите страницу и попробуйте ещё раз.</Trans>{" "}
            </div>
          ) : null}
          {searchDataLoading() ? (
            <div className="results-loading">
              <Rings height={145}/>
            </div>
          ) : items.length ? (
            <div className="results">
              <div className="list">{items}</div>
            </div>
          ) : searchValue() && !loadError() ? (
            <div className="results-text">
              {" "}<Trans>Ничего не найдено. Проверьте написание и&nbsp;попробуйте ещё раз.</Trans>{" "}
            </div>
          ) : null}
        </div>
        <div className="services-list">
          <div className="text-desktop">
            <Trans>Мы поддерживаем все популярные сервисы</Trans>
          </div>
          <div className="text-mobile"><Trans>Мы поддерживаем:</Trans></div>
          <ul>
            <li>
              <a
                  href="https://podcasts.apple.com/"
                  title="Apple Podcasts"
                  target="_blank"
                  rel="noreferrer"
              >
                <img src="/img/logos/apple-podcasts.svg" alt="Apple Podcasts" />
              </a>
            </li>
            {showRussian ? <li>
              <a
                href="https://music.yandex.ru/non-music"
                title="Yandex Music"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/img/logos/yandex-music.svg" alt="Yandex Music" />
              </a>
            </li> : <></>}
            <li>
              <a
                  href="https://spotify.com"
                  title="Spotify"
                  target="_blank"
                  rel="noreferrer"
              >
                <img src="/img/logos/spotify.png" alt="Spotify" />
              </a>
            </li>
            {showRussian ? <li>
              <a
                href="https://open.zvuk.com/cLQ0/e0t3vjnu"
                title="Звук"
                target="_blank"
                rel="noreferrer"
                >
                <img src="/img/logos/sber.svg" alt="ВКонтакте"/>
              </a>
            </li>: <></>}
            <li>
              <a
                href="https://castbox.fm"
                title="Castbox"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/img/logos/castbox.png" alt="Castbox" />
              </a>
            </li>
            <li>
              <a
                  href="https://www.pocketcasts.com"
                  title="Pocket Casts"
                  target="_blank"
                  rel="noreferrer"
              >
                <img src="/img/logos/pocket-casts.svg" alt="Pocket Casts" />
              </a>
            </li>
            <li>
              <a
                href="https://www.stitcher.com"
                title="Stitcher"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/img/logos/stitcher.png" alt="Stitcher" />
              </a>
            </li>
            <li>
              <a
                  href="https://iheart.com"
                  title="iHeart"
                  target="_blank"
                  rel="noreferrer"
              >
                <img src="/img/logos/iheartradio.png" alt="iHeart" />
              </a>
            </li>
            <li>
              <a
                  href="https://player.fm"
                  title="PlayerFM"
                  target="_blank"
                  rel="noreferrer"
              >
                <img src="/img/logos/playerfm.png" alt="PlayerFM" />
              </a>
            </li>
            {showRussian ? <li>
              <a
                  href="https://vk.com/podcasts"
                  title="ВКонтакте"
                  target="_blank"
                  rel="noreferrer"
              >
                <img src="/img/logos/vk.svg" alt="ВКонтакте"/>
              </a>
            </li>: <></>}
            {showRussian ? <li>
              <a
                href="https://soundstream.media"
                title="SoundStream"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/img/logos/soundstream.png" alt="SoundStream" />
              </a>
            </li> : <></>}
            <li>
              <a
                href="https://overcast.fm"
                title="Overcast"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/img/logos/overcast.png" alt="Overcast" />
              </a>
            </li>
            {/*<li>*/}
            {/*  <a*/}
            {/*    href="https://www.breaker.audio"*/}
            {/*    title="Breaker"*/}
            {/*    target="_blank"*/}
            {/*    rel="noreferrer"*/}
            {/*  >*/}
            {/*    <img src="/img/logos/breaker.svg" alt="Breaker" />*/}
            {/*  </a>*/}
            {/*</li>*/}
            <li>
              <a
                href="https://castro.fm"
                title="Castro"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/img/logos/castro.svg" alt="Castro" />
              </a>
            </li>
            <li>
              <a
                href="https://radiopublic.com"
                title="RadioPublic"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/img/logos/radiopublic.png" alt="RadioPublic" />
              </a>
            </li>
            <li>
              <a
                href="https://ru.wikipedia.org/wiki/RSS"
                title="RSS"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/img/logos/rss.png" alt="RSS" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>);
}

export default Search;
